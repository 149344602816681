import { REQUEST_METHODS } from '@/constants/network'
import baseFetch from '@/network/baseFetch'
interface CompliancePolicyStatus {
    isDisplayed: boolean
}
export const getCompliancePolicyStatus = async (): Promise<CompliancePolicyStatus> => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch<CompliancePolicyStatus>({
            url: '/compliance',
            method: REQUEST_METHODS.GET,
            headers: header
        })
        if (response) {
            return response
        }
        return Promise.reject()
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}

export const setCompliancePolicyStatus = async (payload: { action: string }) => {
    try {
        const header = {
            caller: 'UI'
        }
        const response = await baseFetch<ResponseType>({
            url: '/compliance',
            method: REQUEST_METHODS.POST,
            headers: header,
            body: JSON.stringify(payload)
        })
        return response
    } catch (e) {
        console.error(e)
    }
}
