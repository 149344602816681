export const HomeWallet = ({ height = '14', width = '16', fill = '#1a1a1a' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 14" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5 5.125H16C16 4.84886 15.7761 4.625 15.5 4.625V5.125ZM15.5 8.875V9.375C15.7761 9.375 16 9.15114 16 8.875H15.5ZM11.75 6.5C11.4739 6.5 11.25 6.72386 11.25 7C11.25 7.27614 11.4739 7.5 11.75 7.5V6.5ZM11.7575 7.5C12.0336 7.5 12.2575 7.27614 12.2575 7C12.2575 6.72386 12.0336 6.5 11.7575 6.5V7.5ZM2 1.5H14V0.5H2V1.5ZM15 2.5V11.5H16V2.5H15ZM14 12.5H2V13.5H14V12.5ZM1 11.5V2.5H0V11.5H1ZM2 12.5C1.44772 12.5 1 12.0523 1 11.5H0C0 12.6046 0.895431 13.5 2 13.5V12.5ZM15 11.5C15 12.0523 14.5523 12.5 14 12.5V13.5C15.1046 13.5 16 12.6046 16 11.5H15ZM14 1.5C14.5523 1.5 15 1.94772 15 2.5H16C16 1.39543 15.1046 0.5 14 0.5V1.5ZM2 0.5C0.895431 0.5 0 1.39543 0 2.5H1C1 1.94772 1.44772 1.5 2 1.5V0.5ZM11.375 5.625H15.5V4.625H11.375V5.625ZM15 5.125V8.875H16V5.125H15ZM15.5 8.375H11.375V9.375H15.5V8.375ZM11.375 8.375C10.6156 8.375 10 7.75939 10 7H9C9 8.31168 10.0633 9.375 11.375 9.375V8.375ZM11.375 4.625C10.0633 4.625 9 5.68832 9 7H10C10 6.24061 10.6156 5.625 11.375 5.625V4.625ZM11.75 7.5H11.7575V6.5H11.75V7.5Z"
                fill={fill}
            />
        </svg>
    )
}
