import { OutsideClickHandler, Overlay, Container, Typography } from '@cleartrip/ct-design-components'
import ReactDOM from 'react-dom'

import { accoutOptions, tools } from '@/constants/components/homeConstants'
import { getUserAuthValues } from '@/utils/general/user'
import ClevertapReact from '@/analytics/clevertap/clevertap'
import { getNestedField } from '@/utils'

import { ILoggedInDropdownProps } from '../type'
import { useAppDispatch } from '@/redux/store/hooks'
import { getActionPayload } from '@/utils/general/actions'
import { NavigateType } from '@/types'
import { SIGN_OUT_ROUTE } from '@/constants/paths'
import { dataLayerGA } from '@/analytics/GA/dataLayerHelper'
import { ACTION_NAMES } from '@/constants/analytics'

const LoggedInDropdown: React.FC<ILoggedInDropdownProps> = ({ setShowDropdown, accountInfo }) => {
    const dispatcher = useAppDispatch()
    const handleSignout = () => {
        dispatcher(
            getActionPayload('NAVIGATE', {
                type: NavigateType.IN_PAGE_REDIRECT,
                url: SIGN_OUT_ROUTE
            })
        )
    }

    const { countryCode, mobile, username } = accountInfo || {}
    const profileName = getUserAuthValues()?.profileName?.replace('+', ' ')

    const handleSignedInProfileItemClick = (url: string, actionName: string) => {
        dataLayerGA({ clicked_option_name: actionName, section: 'Navigation' }, ACTION_NAMES.accountMenuClicked)
        if (actionName) {
            ClevertapReact.event('flight_UI_Action', {
                action_type: 'Link',
                action_name: actionName,
                action_value: 'signed_in',
                ui_index: null
            })
        }
        if (url.includes('/accounts/hi-five')) {
            ClevertapReact.event('flight_UI_Action', {
                action_name: ACTION_NAMES.hiFiveBannerClick,
                Hi5_entry_point: 'accounts',
                logged_in: 'yes',
                domain: getNestedField(['location', 'hostname'], window)
            })
        }
    }

    const handleProfileItemClick = (actionName: string) => {
        window.localStorage.removeItem('ON_LOGIN_PUSH')
        if (actionName) {
            ClevertapReact.event('flight_UI_Action', {
                action_type: 'Link',
                action_name: actionName,
                action_value: 'signed_in',
                ui_index: null
            })
        }
    }

    return (
        <>
            <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
                <Container
                    className="bg-neutral-100 br-4 p-absolute z-50 r-0 nmr-3 mt-6"
                    css={{
                        marginRight: '-12px',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 4px 12px 0px',
                        minWidth: '370px'
                    }}
                >
                    <Container
                        className="ls-reset br-4 w-100p px-2 dropdown__item c-neutral-900 ta-center"
                        paddingTop="10px"
                        paddingBottom="6px"
                    >
                        <Typography variant="HM4">
                            {mobile && countryCode
                                ? `${countryCode} ${mobile}`
                                : `${username ? username : profileName}`}
                        </Typography>
                    </Container>
                    <Container
                        className="mb-2 mt-1 d-block flex-1"
                        height="1px"
                        width="100%"
                        backgroundColor="#e6e6e6"
                    />
                    <Container className="row">
                        <Container className="col-12 px-2">
                            <Container className="flex flex-middle">
                                <Container className="fs-12 fw-400 p-2" css={{ color: '#b3b3b3' }}>
                                    Account
                                </Container>
                            </Container>
                            <Container>
                                {accoutOptions.map((value, index) => {
                                    return (
                                        <a className="td-none" href={value.link} key={`account-options-${index}`}>
                                            <Container
                                                onClick={() =>
                                                    handleSignedInProfileItemClick(value.link, value.actionName)
                                                }
                                                className="ls-reset br-4 w-100p dropdown__option c-neutral-900 c-pointer flex flex-middle p-2"
                                            >
                                                {<value.icon />}
                                                <Container className="flex flex-between flex-middle">
                                                    <Container className="fs-14 mx-2 c-neutral-900">
                                                        {value.text}
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </a>
                                    )
                                })}
                            </Container>
                        </Container>
                        <Container className="col-12 px-2">
                            <Container className="flex flex-middle">
                                <Container className="fs-12 fw-400 p-2" css={{ color: '#b3b3b3' }}>
                                    Quick tools
                                </Container>
                            </Container>
                            <Container>
                                {tools.map((value, index) => {
                                    return (
                                        <a className="td-none" href={value.link} key={`tools-${index}`}>
                                            <Container
                                                onClick={() => handleProfileItemClick(value.actionName)}
                                                className="ls-reset br-4 w-100p dropdown__option c-pointer flex flex-middle p-2"
                                            >
                                                {<value.icon />}
                                                <Container className="flex flex-between flex-middle">
                                                    <Container className="fs-14 mx-2 c-secondary-500 card-price">
                                                        {value.text}
                                                    </Container>
                                                </Container>
                                            </Container>
                                        </a>
                                    )
                                })}
                            </Container>
                        </Container>
                    </Container>
                    <Container
                        className="mb-2 mt-1 d-block flex-1"
                        height="1px"
                        width="100%"
                        backgroundColor="#e6e6e6"
                    />
                    <Container className="flex flex-right mb-3 mx-3">
                        <span
                            className="fs-14 c-pointer dropdown__underline"
                            style={{ color: '#ff3c2b' }}
                            onClick={handleSignout}
                        >
                            Sign out
                        </span>
                    </Container>
                </Container>
            </OutsideClickHandler>
            {ReactDOM.createPortal(<Overlay customOverlayWrapperClass="z-30 overlay-bg" />, document.body)}
        </>
    )
}
export default LoggedInDropdown
