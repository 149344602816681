import styled from 'styled-components'

const LinkTag = styled.a`
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

export default LinkTag
