import { IComponentProps } from '@/types'
import { Box, Container, Typography } from '@cleartrip/ct-design-components'
import React from 'react'
import { IFooterData } from './type'
import Icon, { getIconFromMapper } from '../Icon'
import LinkTag from '../LinkTag'

const Footer: React.FC<IComponentProps<IFooterData, 'PAGE_FOOTER'>> = props => {
    const {
        data: { data }
    } = props
    const {
        logo: { data: { iconId = '' } = {} },
        metaRedirectLinks = [],
        tradeName = '',
        termsAndConditions = [],
        socialMediaSection,
        deepLinkSections = []
    } = data || {}

    const FooterLogo = getIconFromMapper(iconId)

    return (
        <footer className="bg-neutral-300 w-100vw">
            <Container className={'w-100p bg-neutral-300'}>
                <Container className="bb bc-line-500 w-100p" />
                <Container className="results-slot-container pt-10 ">
                    <Box horizontal boxSize="zero">
                        <FooterLogo />
                        <Box boxSize="medium" className="flex-between h-100p">
                            <Box boxSize="medium" horizontal>
                                {metaRedirectLinks.map(
                                    ({ data: { text }, action: { payload: { url = '' } = {} } = {} }, index) => (
                                        <LinkTag
                                            title={text}
                                            key={`metalink-${index}`}
                                            href={url}
                                            className="fs-12 lh-16 fw-500 maxContent c-neutral-900"
                                        >
                                            {text}
                                        </LinkTag>
                                    )
                                )}
                            </Box>
                            <Box boxSize="zero" horizontal className="w-100p flex-between">
                                <Box boxSize="zero" horizontal>
                                    <p className="fs-10 lh-12 fw-400 c-neutral-700">{tradeName}</p>
                                    {termsAndConditions.map(
                                        ({ data: { text }, action: { payload: { url = '' } = {} } = {} }, index) => (
                                            <LinkTag
                                                title={text}
                                                key={`termslink-${index}`}
                                                href={url}
                                                className="fs-10 lh-12 fw-400 maxContent c-neutral-700 ml-1 "
                                            >
                                                {`· ${text}`}
                                            </LinkTag>
                                        )
                                    )}
                                </Box>
                                <Box boxSize="zero" horizontal>
                                    <Typography variant="P3" color="disabled" className="mx-1">
                                        {socialMediaSection?.title || ''}
                                    </Typography>
                                    {socialMediaSection?.socialLinks.map((value, index) => (
                                        <Container className="mx-1" key={`socialicon-${index}`}>
                                            <LinkTag href={value.action?.payload?.url}>
                                                <Icon data={value} />
                                            </LinkTag>
                                        </Container>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container className="px-10 bg-neutral-300">
                    <Container className="bb bc-line-500 w-100p mt-6" />
                </Container>
                <Container className="results-slot-container">
                    {deepLinkSections.map(({ title, deepLinks }, index) => (
                        <Box boxSize="small" key={`deeplink-section-${index}`} className="py-6 pb-5  w-100p">
                            <Typography variant="B3">{title}</Typography>
                            <Box boxSize="tiny" horizontal className="w-100p flex-wrap">
                                {deepLinks.map(
                                    ({ data: { text }, action: { payload: { url = '' } = {} } = {} }, index) => (
                                        <LinkTag
                                            title={text}
                                            key={`metalink-${index}`}
                                            href={url}
                                            className="fs-12 lh-16 fw-500 maxContent c-neutral-700 link--hover"
                                        >
                                            {text}
                                        </LinkTag>
                                    )
                                )}
                            </Box>
                        </Box>
                    ))}
                </Container>
            </Container>
        </footer>
    )
}

export default Footer
