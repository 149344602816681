export const enum CHIP_ICON_MAPPER {
    SPECIAL_DEALS = 'SPECIAL_DEALS',
    SUPER_SAVER = 'SUPER_SAVER',
    BUDGET_FRIENDLY = 'BUDGET_FRIENDLY',
    PREMIUM_AFFORDABLE = 'PREMIUM_AFFORDABLE',
    LUXURY_STAY = 'LUXURY_STAYS',
    DEAL_OF_THE_DAY = 'DOTD'
}

export const enum CHIP_COLOR_MAPPER {
    SPECIAL_DEALS = 'YELLOW',
    SUPER_SAVER = 'YELLOW',
    BUDGET_FRIENDLY = 'GREEN',
    PREMIUM_AFFORDABLE = 'GREEN',
    LUXARY_STAY = 'GREEN',
    DEAL_OF_THE_DAY = 'BLUE'
}
