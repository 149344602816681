import { useCallback, useEffect, useRef, useState } from 'react'
import baseFetch from './baseFetch'
import { REQUEST_METHODS } from '@/constants/network/common'
import {
    IBaseFetchRequestConfig,
    IFetchRequestConfig,
    HTTPResponseError,
    FetchResponseFormat
} from '@/types/network/common'

/**
 * useFetch hook
 *
 * @param url API end point for request
 *
 * @param requestConfig - Request config that contains body, method, headers, responseType, and init default true
 *
 * @param defaultLoadingState - To avoid extra rerendering we can send default loading state so we can skip one re-render. i.e true
 *
 * @returns  Object containing response, isLoading, fetchData and error
 */
export default function useFetch<ResponseType = unknown>(
    url: string,
    requestConfig?: IFetchRequestConfig<ResponseType>,
    defaultLoadingState = false
) {
    const [isLoading, setLoading] = useState(defaultLoadingState)
    const [response, setResponse] = useState<ResponseType | undefined>(undefined)
    const fetchCountRef = useRef(0)
    const [error, setError] = useState<HTTPResponseError | unknown | undefined>(undefined)

    const {
        method = REQUEST_METHODS.GET,
        body,
        headers,
        responseType = FetchResponseFormat.JSON,
        init = true,
        domain,
        onError,
        onSuccess
    } = requestConfig || {}

    const fetchData = useCallback(
        async (fetchConfig?: IBaseFetchRequestConfig) => {
            fetchCountRef.current += 1 // Increment when a fetch starts
            setLoading(true)
            try {
                const payload = { url, method, body, headers, domain, responseType, ...fetchConfig }
                const apiResponse = await baseFetch<ResponseType>(payload)

                setResponse(apiResponse)
                onSuccess?.(apiResponse, response)
            } catch (apiError) {
                setError(apiError)
                onError?.(apiError)
            } finally {
                fetchCountRef.current -= 1 // Decrement when fetch ends
                if (fetchCountRef.current === 0) {
                    // Update loading state only when all fetches end
                    setLoading(false)
                }
            }
        },
        [url]
    )

    useEffect(() => {
        if (init) fetchData()
        return () => {
            setError(undefined)
            setLoading(false)
            setResponse(undefined)
        }
    }, [fetchData, init])

    return {
        response,
        isLoading,
        error,
        fetchData
    }
}
