import { SVGProps } from 'react'

import {
    SuccessTick,
    TripAdvisorMini,
    ClearTripFlipkartLogo,
    FacebookOutlined,
    TwitterOutlined,
    LinkedInOutlined,
    InstagramOutlined,
    CleartripLogo,
    Location,
    Calendar,
    Pax,
    TripAdvisor,
    Locality,
    PriceOffers,
    StarOutline,
    Star,
    Cross,
    Search,
    TALogoBlack,
    SearchIconRed,
    InfoIcon,
    SliderRightChevron,
    Support,
    DownChevron,
    UpChevron,
    Trips,
    Settings,
    User,
    CleartripLogoOnly,
    Wallet,
    BackIcon,
    ExitIcon,
    CircularStarOutlined,
    SucessOffer,
    DealsOfTheDayIcon,
    SpecialDealIcon,
    SuperSaverIcon,
    BudgetFriendlyIcon,
    DealOfTheDayTransparent,
    MyntraLogo,
    FlipkartVip,
    FlipkartPremium,
    PremiumAffordableIcon,
    SelectedOffer,
    CalendarTick,
    CalendarCross,
    Ncemi,
    Plus,
    SuperCoin,
    LoginUser,
    InfoWarning,
    Success,
    ErrorInfo,
    AlertInfo,
    EditPencil,
    LastMinuteDealsTransparent,
    LastMinuteDeals,
    RhombusPolygon,
    FeatherRight,
    Clap,
    Backpack,
    Briefcase,
    MaskedHeart,
    Home
} from '@/assets'
import { CHIP_ICON_MAPPER } from '@/components/common/Forms/FlatChip/type'
import FeatherLeft from '@/assets/icons/FeatherLeft'
import MoneySack from '@/assets/icons/MoneySack'
import Diamond from '../../assets/icons/Diamond'
import SandClock from '@/assets/icons/SandClock'
import StarOutlineFilter from '@/assets/icons/StarOutlineFilter'
import Tag from '@/assets/icons/Tag'

/**
 * Icon mapper
 *
 * Takes icon key and return corresponding Icon
 */
export const ICON_MAPPER = {
    TA_MINI_LOGO: TripAdvisorMini,
    SUCCESS_TICK: SuccessTick,
    CLEARTRIP_FLIPKART: ClearTripFlipkartLogo,
    FACEBOOK_OUTLINED: FacebookOutlined,
    TWITTER_OUTLINED: TwitterOutlined,
    LINKEDIN_OUTLINED: LinkedInOutlined,
    INSTAGRAM_OUTLINED: InstagramOutlined,
    CT_LOGO: CleartripLogo,
    LOCATION: Location,
    CALENDAR: Calendar,
    PAX: Pax,
    TRIPADVISOR_SMALL: TripAdvisorMini,
    TRIPADVISOR: TripAdvisor,
    STAR: StarOutline,
    STAR_FILLED: Star,
    PLACE: Locality,
    OFFER: PriceOffers,
    SEARCH: Search,
    CROSS: Cross,
    TA_LOGO: TALogoBlack,
    SEARCH_RED: SearchIconRed,
    INFO: InfoIcon,
    BACK: BackIcon,
    RIGHT_CHEVRON: SliderRightChevron,
    MY_TRIPS: Trips,
    SETTINGS: Settings,
    SUPPORT: Support,
    WALLET: Wallet,
    CLEARTRIP_LOGO: CleartripLogoOnly,
    SIGN_OUT: ExitIcon,
    ACCOUNT: User,
    DOWN: DownChevron,
    UP: UpChevron,
    CIRCULAR_STAR_OUTLINED: CircularStarOutlined,
    SUCCESS_OFFER: SucessOffer,
    DOTD_ICON: DealsOfTheDayIcon,
    [CHIP_ICON_MAPPER.SPECIAL_DEALS]: SpecialDealIcon,
    [CHIP_ICON_MAPPER.SUPER_SAVER]: SuperSaverIcon,
    [CHIP_ICON_MAPPER.BUDGET_FRIENDLY]: BudgetFriendlyIcon,
    [CHIP_ICON_MAPPER.PREMIUM_AFFORDABLE]: PremiumAffordableIcon,
    [CHIP_ICON_MAPPER.LUXURY_STAY]: BudgetFriendlyIcon,
    [CHIP_ICON_MAPPER.DEAL_OF_THE_DAY]: DealsOfTheDayIcon,
    DEAL_OF_THE_DAY: DealOfTheDayTransparent,
    BRAND_OF_THE_DAY: DealOfTheDayTransparent,
    MYNTRA: MyntraLogo,
    FLIPKART_VIP: FlipkartVip,
    FLIPKART_PREMIUM: FlipkartPremium,
    SELECTED_OFFER: SelectedOffer,
    NCEMI: Ncemi,
    PLUS: Plus,
    SUPERCOIN: SuperCoin,
    EDIT_PENCIL: EditPencil,
    CALENDAR_TICK: CalendarTick,
    CALENDAR_CROSS: CalendarCross,
    USER_ICON: LoginUser,
    INFO_WARNING: InfoWarning,
    SUCCESS: Success,
    ERROR_INFO: ErrorInfo,
    ALERT_INFO: AlertInfo,
    LAST_MINUTE_DEALS_BADGE: LastMinuteDealsTransparent,
    LAST_MINUTE_DEALS: LastMinuteDeals,
    RHOMBUS_POLYGON: RhombusPolygon,
    FEATHER_LEFT: FeatherLeft,
    FEATHER_RIGHT: FeatherRight,
    HOME: Home,
    MASKED_HEART: MaskedHeart,
    CLAP: Clap,
    BACKPACK: Backpack,
    BRIEFCASE: Briefcase,
    MONEY_SACK: MoneySack,
    DIAMOND: Diamond,
    SAND_CLOCK: SandClock,
    STAR_OUTLINE: StarOutlineFilter,
    TAG: Tag
} as Record<string, React.FC<SVGProps<SVGSVGElement>>>
// TODO: @raghav.bansal add rest props on each icon component
