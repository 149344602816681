import React from 'react'

import { ICON_MAPPER } from '@/constants/icon'
import { formTrackingAction } from '@/utils/actions'
import { isEmpty } from '@/utils/general/browserHelper'

import { IconProps } from './type'

export const getIconFromMapper = (iconId: string) => {
    return ICON_MAPPER[iconId as keyof typeof ICON_MAPPER]
}

const Icon: React.FC<IconProps> = ({ data, dispatcher, className = '', ownProps, ...rest }) => {
    const { data: { iconId = '' } = {}, action, ravenTracking } = data || {}
    const { iconProps } = ownProps || {}
    const { style = {}, iconClassName = '' } = iconProps || {}

    const combinedClassName = `${className} ${iconClassName}`

    const IconToRender = getIconFromMapper(iconId)
    if (!IconToRender) {
        return (
            <span className={`icon ${iconId} ${combinedClassName}`} {...iconProps} {...ownProps} style={style}></span>
        )
    }

    const isActionable = action && dispatcher

    const onClick = () => {
        isActionable && dispatcher?.(action)
        if (!isEmpty(ravenTracking)) {
            dispatcher?.(formTrackingAction(ravenTracking!))
        }
    }

    return (
        <IconToRender
            onClick={onClick}
            className={isActionable ? combinedClassName + ' c-pointer' : combinedClassName}
            {...ownProps}
            {...iconProps}
            {...rest}
        />
    )
}
export default Icon
