export const AccountLogo = ({ height = '16', width = '16', fill = '#999' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" height={height} width={width}>
            <g fill="none" fillRule="evenodd">
                <rect width="14" height="14" fill="#FFF" opacity="0" />
                <circle cx="7" cy="7" r="6.25" stroke={fill} strokeWidth="1.5" />
                <path
                    fill={fill}
                    d="M3,5 C4.38071187,5 5.5,3.88071187 5.5,2.5 C5.5,1.11928813 4.38071187,0 3,0 C1.61928813,0 0.5,1.11928813 0.5,2.5 C0.5,3.88071187 1.61928813,5 3,5 Z"
                    transform="matrix(-1 0 0 1 10 3)"
                />
                <path
                    fill={fill}
                    d="M7,9 C9.14219539,9 10.8910789,10.6839685 10.9951047,12.8003597 L11,13 L3,13 C3,10.790861 4.790861,9 7,9 Z"
                />
            </g>
        </svg>
    )
}
